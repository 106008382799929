.angular-google-map-container {
  height: 400px;
}

select[multiple] {
  min-height: 400px;
}

td {
  &.email {
    max-width: 400px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
